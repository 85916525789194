import classes from './ShowAs.module.css'
import React, { Fragment, useContext, useEffect, useState } from "react";
import FancyToggle from './FancyToggle';

function ShowAs(props) {

    return(
        <div className= {classes.show_as_container}> 
            <h2> Adjust display </h2>
            <div>
                <button className = {props.as_chart ? classes.selected : ''}
                onClick = {() => props.set_as_chart(true)}
                > Chart</button>
                <button 
                onClick = {() => props.set_as_chart(false)}
                className = {!props.as_chart ? classes.selected : ''}> Table</button>
            </div>
        <p className = {classes.more_options}> Show options for salary increases </p>
        
        <div className = {classes.fancy_toggle_container}>
            <p> Hide</p>
        <FancyToggle
        handleChange = {() => props.set_show_options((prev) => !prev)} 
        state = {props.show_options }
        />
        <p> Show</p>
        </div>
        </div>
    )
}

export default ShowAs