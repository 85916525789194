import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import html2canvas from 'html2canvas';
import classes from './DownloadButton.module.css'
import { currencyFormatter, sumValues } from '../../../utilities/Formatters';
import { formatDate } from '../../../utilities/Formatters';

const DownloadPdfButton = (props) => {

  
    const generatePdf = async () => {
        // Initialize jsPDF
        const doc = new jsPDF();
      
        // Add a paragraph of text at the top
        doc.setFont('helvetica','bold'); // Make the text bold
        const pageWidth = doc.internal.pageSize.getWidth();
        const introText = 'Introductory paragraph here.';
        const introTextWidth = doc.getTextWidth(introText);
        doc.text(introText, (pageWidth - introTextWidth) / 2, 10); // Centered text
        doc.setFont('helvetica','normal')

        const chart = document.querySelector('#pension_chart');
        const chartCanvas = await html2canvas(chart);
        const chartImg = chartCanvas.toDataURL('image/png');

        const ls_chart = document.querySelector('#lumpsum_chart');
        const ls_chartCanvas = await html2canvas(ls_chart);
        const ls_chartImg = ls_chartCanvas.toDataURL('image/png');

        const chartWidth = 95
        const chartHeight = (doc.internal.pageSize.getHeight() / 3) ; // Adjust height to take roughly a third of the vertical space
        doc.addImage(chartImg, 'PNG', 10, 20, chartWidth, chartHeight);
        doc.addImage(ls_chartImg, 'PNG', chartWidth + 10, 20, chartWidth, chartHeight);


        // Define the columns of your table
        const tableColumn = [
            "Retirement Age",
            "Salary Increase",
            "Option",
            props.legacySchemeName + ' pension',
            props.remedySchemeName + ' pension',
            "Total Pension",
            "Lump Sum",
          ];
      
          let tableRows = [];

          const baseRow = [
            props.outputData.retirementAge,
            props.outputData.salaryIncrease + "%",
            'Option A - standard benefits', 
            currencyFormatter(props.pension.choose_legacy.old),
            currencyFormatter(props.pension.choose_legacy.new),
            currencyFormatter(sumValues([
              props.pension.choose_legacy.old,
              props.pension.choose_legacy.new,
            ])),
            currencyFormatter(props.lump_sum["choose_legacy"]),
          ];
        
          // Push the base row into tableRows
          tableRows.push(baseRow);
        
          // Assuming you have similar data for the 'reformed' scheme
          const reformedRow = [
            props.outputData.retirementAge,
            props.outputData.salaryIncrease + "%",
            'Option B - alternative benefits', 
            currencyFormatter(props.pension.choose_reformed.old),
            currencyFormatter(props.pension.choose_reformed.new),
            currencyFormatter(sumValues([
              props.pension.choose_reformed.old,
              props.pension.choose_reformed.new,
            ])),
            currencyFormatter(props.lump_sum["choose_legacy"]),
          ];
        
          // Push the reformed row into tableRows
          tableRows.push(reformedRow);
        
          // Add rows from saved_scenarios_ref
          props.saved_scenarios_ref.current.forEach(scenario => {
            const { classic, alpha } = scenario;
        
            const classicRow = [
              classic['Retirement Age'],
              classic['Salary Increase'],

              'Option A - Standard Benefits',
              classic[props.legacySchemeName + ' pension'],
              classic[props.remedySchemeName + ' pension'],
              classic['Total Pension'],
              classic['Lump Sum'],
            ];
        
            const alphaRow = [
              alpha['Retirement Age'],
              alpha['Salary Increase'],

              'Option B - Alternative Benefits',
              alpha[props.legacySchemeName + ' pension'],
              alpha[props.remedySchemeName + ' pension'],
              alpha['Total Pension'],
              alpha['Lump Sum'],
            ];
        
            tableRows.push(classicRow, alphaRow);
          });

        const tableStartY = 20 + chartHeight  + 20;
      
        // Add the table to the PDF
        doc.text('Text about the table here.', 10, tableStartY - 10);
        doc.autoTable({
          columns: tableColumn,
          body: tableRows,
          startY: tableStartY, // Start the table after the introductory text
          styles: { fontSize: 8 }, // Customize table styles
          theme: 'grid',
        });

        const other_inputs_column = [
            "Date of Birth",
            "Joining Date",
            "Status",
            "Service as of 2022",
            "Current Salary",
            "Salary in April 2015",
          ];
          
        const other_inputs_data = [
            formatDate(props.inputsRef.current.dateOfBirth), 
            formatDate(props.inputsRef.current.dateOfJoining),
            props.inputsRef.current.status === 'Neither of these' ? 'Neither special class nor MHO' : props.inputsRef.current.status,
            props.inputsRef.current.Service2022 + ' years',
            currencyFormatter(props.inputsRef.current.salaryCurrent), 
            currencyFormatter(props.inputsRef.current.salary2015)
        ]

        doc.text('More text here', 10, tableStartY + 40 + 20 * props.saved_scenarios_ref.current.length)
        doc.autoTable({
            columns: other_inputs_column,
            body: [other_inputs_data],
            startY: tableStartY + 50 + 20 * props.saved_scenarios_ref.current.length, // Start the table after the introductory text
            styles: { fontSize: 8 }, // Customize table styles
            theme: 'grid',
          });

        const part_time_column = [
            'From', 
            'To', 
            'Part-time hours', 
            'Full-time hours'
        ]

        const part_time_data = props.inputsRef.current.partTimeService.map(
            (x) => {
                return(
                    [formatDate(x['From']), formatDate(x['To']), x['PartTimeHours'], x['FullTimeHours']]
                )
            }
        )

        if(part_time_data.length > 0) {
            doc.text('Text about part-time service here', 10, tableStartY + 90 + 20 * props.saved_scenarios_ref.current.length)
            doc.autoTable({
                columns: part_time_column,
                body: part_time_data,
                startY: tableStartY + 100 + 20 * props.saved_scenarios_ref.current.length, // Start the table after the introductory text
                styles: { fontSize: 8 }, // Customize table styles
                theme: 'grid',
            });

        } else {
          doc.text('Text saying no part-time service has been recorded', 10, tableStartY + 90 + 20 * props.saved_scenarios_ref.current.length)
        }

        // Save the PDF
        doc.save('your-data.pdf');
      };

  

  return (
    <div className = {classes.download_button_container}> 
    <button
    className = {classes.download_button}
    onClick={generatePdf}>Download PDF</button>
    </div>
  );
};

export default DownloadPdfButton;
